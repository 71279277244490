import React from 'react';
import { Spinner } from 'react-bootstrap';
import './LoadingSpinner.scss';

class LoadingSpinner extends React.Component {
  render() {
    return (
        <div className='SpinnerContainer d-flex spinner'>
            <Spinner animation='border' role='status' >
                <span className='sr-only'>...Loading</span>
            </Spinner>
        </div>
    );
  }
}

export default LoadingSpinner;
