import React, { Component } from 'react';
import { Col, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import TimePicker from 'react-time-picker';
import { TiCalendarOutline } from 'react-icons/ti';
import { IconContext } from 'react-icons';

import 'react-datepicker/dist/react-datepicker.css';
import './Date.scss';
import './Time.scss';
import moment from 'moment';


class DateTime extends Component {
  constructor(props) {
    super(props);
    this.state = { dateSelected: null };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleTimeChange = this.handleTimeChange.bind(this);
  }

  handleDateChange(date) {
    this.props.inputChange(this.props.dateStateName, date);
    const startDate = moment(date).format('MM/DD/YYYY');
    const endDate = moment(this.props.state.endDate).format('MM/DD/YYYY');
    if (this.props.dateStateName === 'startDate' && moment(endDate).isBefore(startDate)) {
      this.props.inputChange('endDate', date);
    }
  }

  handleTimeChange(time) {
    this.props.inputChange(this.props.timeStateName, time);
  }

  render() {
    return (
        <Row>
            <Col md={4}>
                <span className="stationary-floating-date-label">{this.props.dateLabel}</span>
                <span className="formDateIcon">
                    <IconContext.Provider value={{ color: '#919191', size: '2em' }}>
                        <TiCalendarOutline />
                    </IconContext.Provider>
                </span>
                <DatePicker className="inputDate" minDate={this.props.minDate} selected={this.props.date} onChange={this.handleDateChange} />
            </Col>
            <Col md={6}>
                <span className="floating-time-label">{this.props.timeLabel}</span>
                <TimePicker className="inputTime" value={this.props.time} onChange={this.handleTimeChange} format="h:mm a" disableClock clearIcon={null} />
            </Col>
        </Row>
    );
  }
}

export default DateTime;
