import React from 'react';
import Toast from 'react-bootstrap/Toast';
import { FaInfoCircle } from 'react-icons/fa';
import './PopUp.scss';

class PopUp extends React.Component {
  render() {
    return (
        <Toast show={this.props.toastValue.status} onClose={() => { this.props.closeToast(); }}>
            <Toast.Header className={this.props.toastValue.type}>
                <FaInfoCircle />
                <strong className="mr-auto">{this.props.toastValue.type}</strong>
            </Toast.Header>
            <Toast.Body>{this.props.toastValue.apiMessage}</Toast.Body>
        </Toast>
    );
  }
}

export default PopUp;
