import { AuthenticationContext } from 'react-adal';
import dotenv from 'dotenv';

dotenv.config();

export const adalConfig = {
  tenant: process.env.REACT_APP_TENANT_ID,
  clientId: process.env.REACT_APP_APP_ID,
  redirectUri: process.env.REACT_APP_REDIRECT_URI,
  cacheLocation: 'sessionStorage'
};


export const authContext = new AuthenticationContext(adalConfig);

export const getUserDetails = () => authContext.getCachedUser(adalConfig.clientId);

export const getToken = () => authContext.getCachedToken(adalConfig.clientId);
