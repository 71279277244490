import { getUserDetails } from '../adalConfig';

const UserService = {
  getUserMail() {
    const userDetail = getUserDetails();
    const userMail = userDetail.userName;
    return userMail;
  },

  getUserName() {
    const userDetail = getUserDetails();
    const familyName = userDetail.profile.family_name;
    const givenName = userDetail.profile.given_name;
    const userName = `${familyName} ${givenName}`;
    return userName;
  }
};

export default UserService;
