import React, { Component } from 'react';
import { MdSearch, MdErrorOutline } from 'react-icons/md';
import { IconContext } from 'react-icons';
import { FormLabel, FormGroup, Button } from 'react-bootstrap';
import './TextInput.scss';


class TextInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: null,
      inputStyleName: 'inputText'
    };
  }

  componentDidMount() {
    this.changeInputStyleClass();
  }

  componentDidUpdate(nextProps) {
    if (nextProps.invalid !== this.props.invalid || nextProps.searchTerm !== this.props.searchTerm) {
      this.changeInputStyleClass();
    }
  }

  changeInputStyleClass() {
    if (this.props.invalid) {
      this.setState({ inputStyleName: 'invalidInputText' });
    } else if (!this.props.required && this.props.searchTerm.trim() === '') {
      this.setState({ inputStyleName: 'optionalEmptyInputText' });
    } else {
      this.setState({ inputStyleName: 'inputText' });
    }
  }

  handleTextChange(event) {
    this.setState({ value: event.target.value });
    this.props.handleContactSelect(event.target.value, '', false);
  }

  handleSearch() {
    this.props.getSearchResults(this.state.value);
    this.props.triggerList();
  }

  render() {
    return (
        <FormGroup>
            <FormLabel>{this.props.mainLabel}</FormLabel>
            <input onChange={(event) => this.handleTextChange(event)} value={this.props.searchTerm} type="text" className={this.state.inputStyleName} required={this.props.required} />
            <Button className="formSearchIcon" onClick={() => (this.handleSearch())}>
                <IconContext.Provider value={{ color: '#1A1446', size: '2em' }}>
                    <MdSearch />
                </IconContext.Provider>
            </Button>
            <span className="floating-label">{this.props.floatLabel}</span>
            <div className="inputError">
                {this.props.invalid && (
                <div>
                    <IconContext.Provider value={{ size: '1.1em' }}>
                        <MdErrorOutline /> {this.props.errorMessage}
                    </IconContext.Provider>
                </div>
                )
            }
            </div>
        </FormGroup>
    );
  }
}

export default TextInput;
