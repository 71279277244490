/* eslint-disable no-param-reassign */
import axios from 'axios';
import dotenv from 'dotenv';
import { getToken } from './adalConfig';

dotenv.config();

// Add a request interceptor
axios.interceptors.request.use(
  (config) => {
    const token = getToken();
    config.baseURL = process.env.REACT_APP_API_URL;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);


// Add a response interceptor
axios.interceptors.response.use((response) =>
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  response);
